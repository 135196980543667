@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  th {
    @apply whitespace-nowrap;
  }
}

@layer components {
  .header {
    @apply m-2 text-3xl font-light;
  }
  .subHeading {
    @apply my-1 text-[14px] font-medium text-gray-400;
  }

  .inputLabel {
    @apply text-xl;
  }

  .primaryButton {
    @apply bg-darkAsscent dark:bg-primaryColor focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 px-4 py-2 text-white rounded-lg;
  }

  .secondaryButton {
    @apply bg-lightBtnBackground hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-800 border-2 border-gray-200 rounded-md shadow-sm;
  }

  .transparentButton {
    @apply px-9 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 py-3 text-white bg-transparent;
  }

  .redButton {
    @apply bg-danger px-9 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 py-2 text-white;
  }

  .greenButton {
    @apply bg-success px-9 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 py-2 text-white;
  }

  .addButton {
    @apply hover:bg-gray-100 text-darkAsscent dark:text-primaryColor border-darkAsscent dark:border-primaryColor hover:text-white hover:border-white hover:bg-primaryColor flex items-center gap-2 px-3 py-2 text-lg border-2 rounded-lg;
  }

  .tableHead {
    @apply text-primaryColor text-start px-5 py-3;
  }

  .tableData {
    @apply text-start px-5 py-3;
  }

  .tableDataActionContainer {
    @apply flex justify-start space-x-2;
  }

  .tableActionContainer {
    @apply bg-lightBtnBackground hover:bg-gray-300 p-2 rounded-md cursor-pointer;
  }

  .tableEditIcon {
    @apply text-darkAsscent dark:text-primaryColor h-5;
  }

  .tableDeleteIcon {
    @apply h-5 text-red-500;
  }
  .sidebar {
    @apply flex flex-col items-center fixed font-sans font-semibold text-gray-900  dark:text-white dark:bg-darkBackground bg-gray-50 py-3 px-2 h-full hide-scroll-bar min-w-[4.5rem] overflow-x-hidden transition-[width] duration-[150ms] ease-in-out drop-shadow-lg origin-left z-20;
  }

  .green-badge {
    @apply text-green-700 bg-green-200;
  }

  .yellow-badge {
    @apply text-yellow-700 bg-yellow-200;
  }

  .gray-badge {
    @apply text-gray-700 bg-gray-300;
  }

  .label {
    @apply block text-[14px] font-medium leading-[150%] text-[#202324];
  }

  .input-field {
    @apply py-3.5 px-4 w-full text-[14px] block font-normal leading-[150%] rounded-lg text-[#6D7578] border border-[#D5D6D7] focus:border-primaryColor;
  }
}

@layer utilities {
  .hide-for-transition {
    @apply absolute invisible h-0;
  }

  .hide-scroll-bar {
    overflow-y: scroll; /* Add the ability to scroll */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scroll-bar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* Styles for the input */
.react-tags-wrapper {
  @apply flex flex-col gap-3 mt-2;
}

.ReactTags__tagInput {
  @apply inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField {
  @apply h-8 px-3 py-5 rounded-md text-sm min-w-[350px] block flex-1 border-gray-300 focus:border-darkAsscent focus:ring-darkAsscent sm:text-sm;
}

.ReactTags__editInput {
  @apply px-2 py-4 rounded-md;
}

.ReactTags__editTagInput {
  @apply inline-block;
}

.ReactTags__editTagInput > input {
  @apply min-w-fit inline-block px-2 py-1 border;
}

/* Styles for selected tags */
.ReactTags__selected {
  @apply flex flex-wrap w-fit max-w-[350px] gap-1;
}

.ReactTags__selected span.ReactTags__tag {
  @apply bg-primaryColor dark:bg-darkAsscent w-fit inline-flex justify-around h-8 px-3 py-1 text-gray-100 rounded-full;
}
.ReactTags__selected .ReactTags__remove {
  @apply ml-2;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  @apply absolute;
}

.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}

.ellipsis-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
